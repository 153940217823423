<template>
    <div>
        <img src="../../../assets/image/touchballball/mycard_bg.jpg" class="bg" />
        <div class="titleBar newTitleBar">
            <img class="back" src="../../../assets/image/ic_back.png" v-on:click="back()"/>
            <div class="title">{{ $t("touchball.card.title") }}</div>
            <img class="back" src="../../../assets/image/ic_more.png" v-on:click="showMenu()" style="z-index: 2;"/>
            <div class="rightMenu" v-if="showRightMenu">
                <img class="icon_menu" style="margin-top: 40px;" src="../../../assets/image/ic_collect.png" v-on:click="shareAllCard()"/>
                <img class="icon_menu" style="margin-top: 10px;" src="../../../assets/image/ic_share_all.png" @click="jumpToCollect()"/>
            </div>
        </div>

        <div class="sub_title">{{ $t("touchball.card.subtitle")}}</div>
        
        <van-grid :gutter="10" :border="false" :column-num="3" style="padding-left: 0rem;" class="grid">
            <van-grid-item v-for="(item,index) in list" :key="index" style="padding: 0px;margin-top: 0px;">
                <div @touchstart="gtouchstart(item,index)"
                    @touchmove="gtouchmove()"
                    @touchend="showDeleteButton(item, index)"
                    >
                    <!-- <div class="no_card"></div> -->
                    <!-- <img class="item_img" src="../../../assets/image/touchballball/card_0.png" /> -->
                    <!-- <div v-if="item==0" class="no_card"></div> -->
                    <img class="item_img" v-if="item==0" :src="bimageList[index]" />
                    <img class="item_img" v-else :src="imageList[index]" />
        
                    <span v-if="item == 0" class="item_txt"></span>
                    <span v-else class="item_txt">x{{item}}</span>
                </div>
            </van-grid-item>
        </van-grid>
       

        <div class="bottom">
            <div class="bottom_text">
                {{ $t("touchball.card.score")}}:<span>{{score}}</span>
            </div>
            <img :src="getBtn()" class="bottom_btn" @click="toClick()"/>
            <div class="bottom_text">
                {{ $t("touchball.card.card")}}:{{cardCount}}
            </div>
        </div>

        <van-popup v-model:show="showCard">
            <div class="pop">
                <img class="pop_card" :src="imageList[currentCard]"/>
                <img class="pop_close" src="../../../assets/image/touchballball/ic_invitation_delete.png" @click="closePop()"/>
            </div>
        </van-popup>

        <van-popup v-model:show="showNotice">
            <div class="notice">
                <div class="notice_txt">
                    {{ $t("touchball.card.notice.text")}}
                </div>
                <div class="notice_close" @click="closeNotice()">
                    {{ $t("touchball.card.notice.close")}}
                </div>
            </div>
        </van-popup>

        <div class="shareImg" ref="shareDiv" v-if="shareImg">
            <img src="../../../assets/image/touchballball/mycard_bg.jpg" class="bg" />
            
            <div class="titleBar newTitleBar">
                <img class="back" src="../../../assets/image/ic_back.png" style="visibility: hidden;" />
                <div class="title">{{ $t("touchball.card.title") }}</div>
                <img class="back" src="../../../assets/image/ic_more.png" style="visibility: hidden;" />
                
            </div>

            <div class="sub_title">{{ $t("touchball.card.subtitle")}}</div>
            
            <van-grid :gutter="10" :border="false" :column-num="3" style="padding-left: 0rem;" class="grid">
                <van-grid-item v-for="(item,index) in list" :key="index" style="padding: 0px;margin-top: 0px;">
                    <div>
                        <img class="item_img" v-if="item==0" :src="bimageList[index]" />
                        <img class="item_img" v-else :src="imageList[index]" />
            
                        <span v-if="item == 0" class="item_txt"></span>
                        <span v-else class="item_txt">x{{item}}</span>
                    </div>
                </van-grid-item>
            </van-grid>

            <img class="qr" src="../../../assets/image/touchballball/share_qr.png"/>
        </div>

        <div class="share_div" v-if="shareDiv">
            <div class="share_all">
                <img :src="shareCards" class="shareCards" />
            </div>
            
            <div class="share_bottom">
                <img class="saveBtn" src="../../../assets/image/ic_save.png" @click="downloadImage(shareAllData)"/>
                <div class="cancel" @click="shareDiv = false">{{ $t("touchball.card.cancel") }}</div>
            </div>
        </div>

        <div class="share_card" v-if="shareSingleCard">
            
            <div class="share_card_bg" ref="singleCard">
                <img class="item_img_s" :src="imageList[selectIndex]" />
                <img class="qr_s" v-if="qrUrl" :src="qrUrl" />
            </div>

            <div class="share_bottom card_bottom">
                <div class="share" @click="shareToSingleCard">{{ $t("touchball.card.share") }}</div>
                <div class="share" @click="giveSCard">{{ $t("touchball.card.give") }}</div>
                <div class="share" @click="shareSingleCard = false">{{ $t("touchball.card.cancel") }}</div>
            </div>
        </div>

    </div>

</template>

<script>
    import { getUserRaffle, raffle, giveCard } from '../../../api/jump/collect'
    import html2canvas from "html2canvas";
    import QRCode from "qrcode"

    const homeUrl = "https://touchballball.kelemetaverse.com/#/"

    export default{
        data(){
            return {
                score:0,
                cardCount:0,
                list: [0,0,0,0,0,0,0,0,0],
                imageList: [
                    require("../../../assets/image/touchballball/card_0.png"),
                    require("../../../assets/image/touchballball/card_1.png"),
                    require("../../../assets/image/touchballball/card_2.png"),
                    require("../../../assets/image/touchballball/card_3.png"),
                    require("../../../assets/image/touchballball/card_4.png"),
                    require("../../../assets/image/touchballball/card_5.png"),
                    require("../../../assets/image/touchballball/card_6.png"),
                    require("../../../assets/image/touchballball/card_7.png"),
                    require("../../../assets/image/touchballball/card_8.png"),
                    require("../../../assets/image/touchballball/card_8.png"),
                    require("../../../assets/image/touchballball/card_8.png"),
                    require("../../../assets/image/touchballball/card_8.png"),
                ],
                bimageList: [
                    require("../../../assets/image/touchballball/bcard_0.png"),
                    require("../../../assets/image/touchballball/bcard_1.png"),
                    require("../../../assets/image/touchballball/bcard_2.png"),
                    require("../../../assets/image/touchballball/bcard_3.png"),
                    require("../../../assets/image/touchballball/bcard_4.png"),
                    require("../../../assets/image/touchballball/bcard_5.png"),
                    require("../../../assets/image/touchballball/bcard_6.png"),
                    require("../../../assets/image/touchballball/bcard_7.png"),
                    require("../../../assets/image/touchballball/bcard_8.png"),
                    require("../../../assets/image/touchballball/bcard_8.png"),
                    require("../../../assets/image/touchballball/bcard_8.png"),
                    require("../../../assets/image/touchballball/bcard_8.png"),
                ],
                currentCard: 0,
                showCard:false,
                showNotice:false,
                showRightMenu:false,
                shareCards:"",
                shareImg:false,
                shareDiv:false,
                shareSingleCard:false,
                selectIndex: 0,
                qrUrl: "",
                cardNames:["一夜暴富卡","OFFER卡","满分卡","脱单卡","永保健康卡","瘦身卡","福星高照卡","早日躺平卡","颜值爆表卡"]
            }
        },
        created(){
            let url = window.location.href
            if (url.indexOf("?") == -1) {//if has no token jump to home
                window.location.replace("https://touchballball.kelemetaverse.com")
            } else {
                let param = url.substring(url.indexOf("?"))
                let arr = this.urlParse(param);
                // window.localStorage.setItem("token",arr.token)
                window.token = arr.token
            }
        },
        mounted(){
            this.getCards()
            // this.$toast.fail({
            //     message: this.$t('touchball.card.nocard')
            // })

            // this.$nextTick(()=>{
            //     this.toImage()
            // })
            
        },
        methods: {
            back(){
                uni.navigateBack({
                    delta: 1
                });
            },
            getBtn(){
                if(this.isZh()){
                    return require('../../../assets/image/touchballball/card_btn_cn.png')
                }else{
                    return require('../../../assets/image/touchballball/card_btn_en.png')
                }
                
            },
            getCards(){
                getUserRaffle({ gameType: 3, difficulty :1}).then(response =>{
                    if(response.code == 200){
                        this.list = response.data.cardInfo
                        this.score = response.data.score
                        this.cardCount = response.data.cardSum
                    }
                })
            },
            toClick(){
                let flag = false
                if (this.list) {
                    for (let i = 0; i < this.list.length; i++) {
                        if (this.list[i] == 0) {
                            flag = true
                            break
                        }
                    }
                }

                if (flag) {
                    this.showNotice = true
                    return
                }

                raffle({ gameType: 3, difficulty: 1 }).then(response =>{
                    if (response.code == 200) {
                        if(response.data.card == false){
                            this.$toast.fail({
                                message: this.$t('touchball.card.nocard')
                            })
                        }else{
                            this.currentCard = response.data.cardIndex
                            this.showCard = true
                            
                        }
                        this.getCards()
                    }
                })
            }, 
            closeNotice(){
                this.showNotice = false
            },
            closePop(){
                this.showCard = false
            },
            isZh(){
                if (this.getDefaultLang() == "zh"){
                    return true
                }
                return false
            },
            getDefaultLang (){
                if (navigator.language == 'zh-CN') {
                    return 'zh';
                } else if (navigator.language == 'en') {
                    return 'en';
                } else {
                    return 'en';
                }
            },
            urlParse (url) {
                let obj = {};
                let reg = /[?&][^?&]+=[^?&%]+/g;
                let arr = url.match(reg);
                arr.forEach((item) => {
                    let tempArr = item.substring(1).split('=');
                    let key = decodeURIComponent(tempArr[0]);
                    let val = decodeURIComponent(tempArr[1]);
                    obj[key] = val;
                })
                return obj;
            },
            showMenu(){
                this.showRightMenu = !this.showRightMenu
            },
            shareAllCard(){
                this.shareImg = true
                this.showRightMenu = false
                this.$nextTick(()=>{
                    this.toImage()
                })
            },
            toImage () {
                const canvas = document.createElement("canvas")
                let canvasBox = this.$refs.shareDiv
                const width = parseInt(window.getComputedStyle(canvasBox).width)
                const height = parseInt(window.getComputedStyle(canvasBox).height)
                canvas.width = width * 2
                canvas.height = height * 2
                canvas.style.width = width + 'px'
                canvas.style.height = height + 'px'
                canvas.style.background = 'transparent';
                const context = canvas.getContext("2d");
                context.scale(2, 2);
                const options = {
                    backgroundColor: null,
                    canvas: canvas,
                    useCORS: true,
                    scale: 1,
                }
                html2canvas(canvasBox, options).then((canvas) => {
                    let dataURL = canvas.toDataURL("image/png")
                    this.shareCards = dataURL
                    this.shareImg = false
                    this.shareDiv = true
                    this.shareAllData = dataURL
                })
            },
            downloadImage (url) {
                let a = document.createElement('a')
                a.href = url
                a.download = 'card'
                a.click()
            },
            shareToSingleCard(){
                console.log(homeUrl)
                QRCode.toDataURL(homeUrl, {
                    //这个this.url就是你扫码后要跳转的地址
                    version: 7, //版本号
                    errorCorrectionLevel: "Q", //容错率,(建议选较低)更高的级别可以识别更模糊的二维码，但会降低二维码的容量
                    width: 280, //设置二维码图片大小
                    height: 280,
                })
                .then((url) => {
                    console.log(url)
                    this.qrUrl = url
                    this.$nextTick(() => { this.toShareToSingleCard() })
                })
                .catch((err) => {
                    console.error(err);
                });
            },
            giveSCard () {
                giveCard({ gameType: 3, difficulty: 1,cardIndex:this.selectIndex }).then(response => {
                    if (response.code == 200) {
                        if (!response.data.cardNo) {
                            this.$toast.fail({
                                message: this.$t('touchball.card.giveError')
                            })
                        } else {
                            console.log(homeUrl + "?cardId=" + response.data.cardNo + "&cardName=" + this.cardNames[this.selectIndex])
                            QRCode.toDataURL(homeUrl + "?cardId=" + response.data.cardNo + "&cardName=" + this.cardNames[this.selectIndex], {
                                //这个this.url就是你扫码后要跳转的地址
                                version: 8, //版本号
                                errorCorrectionLevel: "Q", //容错率,(建议选较低)更高的级别可以识别更模糊的二维码，但会降低二维码的容量
                                width: 280, //设置二维码图片大小
                                height: 280,
                            })
                                .then((url) => {
                                    console.log(url)
                                    this.qrUrl = url
                                    this.$nextTick(() => { this.toShareToSingleCard() })
                                })
                                .catch((err) => {
                                    console.error(err);
                                });
                        }
                    }else{
                        this.$toast.fail({
                            message: this.$t('touchball.card.giveError')
                        })
                    }
                })
                
            },
            toShareToSingleCard(){
                const canvas = document.createElement("canvas")
                let canvasBox = this.$refs.singleCard
                const width = parseInt(window.getComputedStyle(canvasBox).width)
                const height = parseInt(window.getComputedStyle(canvasBox).height)
                canvas.width = width * 5
                canvas.height = height * 5
                canvas.style.width = width + 'px'
                canvas.style.height = height + 'px'
                console.log(canvas)
                canvas.style.background = 'transparent';
                const context = canvas.getContext("2d");
                context.scale(5, 5);
                const options = {
                    backgroundColor: null,
                    canvas: canvas,
                    useCORS: true,
                    scale: 1,
                }
                html2canvas(canvasBox, options).then((canvas) => {
                    let dataURL = canvas.toDataURL("image/png")
                    let a = document.createElement('a')
                    a.href = dataURL
                    a.download = 'single_card'
                    a.click()
                    // this.$toast.success({
                    //     message:"success"
                    // })
                })
            }, 
            gtouchstart (item, index) {
                var self = this;
                this.timeOutEvent = setTimeout(function () {
                    self.longPress(item,index);
                }, 500); 
                return false;
            },
            showDeleteButton (item,index) {
                clearTimeout(this.timeOutEvent); 
                if (this.timeOutEvent != 0) {
                    this.longPress(item, index);
                }
                return false;
            },
            gtouchmove () {
                clearTimeout(this.timeOutEvent); 
                this.timeOutEvent = 0;
            },
            longPress (val,index) {
                this.timeOutEvent = 0;
                if(val > 0){
                    this.shareSingleCard = true
                    this.selectIndex = index
                }
            },
            jumpToCollect(){
                this.$router.push({ path: '/touchballball/collectLikes?key=' + window.token });
            },
            
        },
    }
</script>

<style scoped>
.share_card{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
}

    .share_card_bg{
        position: absolute;
        background-image: url("../../../assets/image/touchballball/bg_share_card.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        top: 15%;
        width: 17.125rem;
        height: 27.625rem;
        left: 50%;
        margin-left: -8.5625rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        align-content: center;
    }

    .item_img_s{
        width: 13.75rem;
        height: 19.25rem;
        /* top: 50%; */
        margin-top: 2.5rem;
    }

    .qr_s{
        width: 4.375rem;
        height:4.375rem;
        background-color: white;
        align-self: flex-end;
        margin-top: .625rem;
        margin-right: 1.25rem;

    }

    .card_bottom{
        height: 11.4375rem !important;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .share{
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: .625rem;
        width: 13.3125rem;
        height: 2.9063rem;
        line-height: 2.9063rem;
        text-align: center;
        font-size: 1.5625rem;
        margin-top: .625rem;
    }

    .shareImg{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 3;
    }

    .share_bottom{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-image: url("../../../assets/image/touchballball/bg_share_bottom.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 15%;
    }

    .saveBtn{
        position: relative;
        left: 50%;
        margin-left:-1.25rem;
        width: 2.5rem;
        height: 2.5rem;
        margin-top: 3%;
    }

    .cancel{
        position: relative;
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 3.125rem;
        width: 9.375rem;
        height:2.5rem;
        text-align: center;
        line-height:2.5rem;
        left: 50%;
        margin-left: -4.6875rem;
    }
    .shareCards{
        position: fixed;
        width: 65%;
        height: 65%;
        top: 10%;
        left:15%;
        border-radius: .625rem;
        border: #ffb312 .125rem solid;
    }
    
    .qr{
        width: 6.25rem;
        height: 6.25rem;
        position:relative;
        /* bottom: 6.25rem;*/
        background-color: white;
        margin-left: 70%;
        margin-top: -12.5rem;
    }

    .share_div{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
    }

    .bg {
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
    }

    .newTitleBar {
        align-items: flex-start;
    }

    .title {
        background-image: url("../../../assets/image/touchballball/title_bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width:12.5rem;
        height: 3.125rem;
        line-height: 3.125rem;
        font-size: 1.875rem;
        font-weight: bold;
        color: white;
        text-align: center;
    }

    .titleBar {
        display: flex;
        flex-direction: row;
        align-self: center;
        align-items: center;
        justify-content: space-between;
        margin-top: 2.0625rem;
    }

    .back {
        width: 2.1875rem;
        height: 2.1875rem;
        margin: 0rem 1rem;
    }

    .rightMenu{
        display: flex;
        flex-direction: column;
        position: absolute;
        background-color: rgba(255, 255, 255, 0.3);
        width: 2.1875rem;
        border-radius: 3.125rem;
        align-items: center;
        align-self: center;
        align-content: center;
        right:1rem;
        height:6.875rem;
        top:2.625rem;

    }

    .icon_menu{
        width: 1.75rem;
        height: 1.75rem;
    }

    .grid{
        /* position: absolute;
        bottom: 240px; */
        padding-bottom: 5rem;
    }

    .sub_title {
        background-image: url("../../../assets/image/touchballball/subtitle_bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 15.625rem;
        height: 1.875rem;
        line-height: 1.875rem;
        margin: .9375rem auto;
        color: white;
        text-align: center;
        font-size: .875rem;
    }
    .no_card {
        border-radius: .3125rem;
        background-color: #ffb312;
        height: 10.625rem;
        width: 7.5rem;
    }

    .item_txt{
        position: absolute;
        left:46%;
        color:white;
        bottom: .25rem;
    }

    .item_img {
        border-radius: .3125rem;
        width: 7.5rem;
        height: 10.625rem;
    }

    .bottom {
        position: fixed;
        bottom: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-self: center;
        align-items: center;
    }

    .bottom_text {
        background-color: #b61c09;
        border-radius: 1.5625rem;
        width: 7.8125rem;
        height: 1.875rem;
        margin: .3125rem;
        text-align: center;
        line-height: 1.875rem;
        color: white;
        font-size: 1.25rem;
    }


    .bottom_btn {
        width: 6.25rem;
        height: 6.25rem
    }

    .pop {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .pop_card {
        width: 12.5rem;
        height: 18.75rem;
    }

    .pop_close {
        margin-top: .625rem;
        width: 2.5rem;
        height: 2.5rem;
    }

        .notice {
            background-image: url("../../../assets/image/touchballball/notice_bg.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            width: 18.75rem;
            height: 9.375rem;
            padding-top: 3.4375rem;
            display: flex;
            flex-direction: column;
            align-self: center;
            align-content: center;
            align-items: center;
        }
    
        .notice_txt {
            color: white;
            font-size: 1.4063rem;
            margin: 0rem .625rem;
        }
    
        .notice_close {
            background-image: url("../../../assets/image/touchballball/notice_close.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            width: 9.375rem;
            height: 2.5rem;
            text-align: center;
            color: white;
            margin-top: 1.25rem;
            line-height: 2.5rem;
            font-size: 1.25rem;
        }
</style>

<style>
:root:root {
    --van-grid-item-content-background-color: rgba(0, 0, 0, 0);
    --van-popup-background-color:rgba(0, 0, 0, 0);
    --van-grid-item-content-padding:0rem;
    /* --van-dialog-width:20rem;
    --van-dialog-message-font-size:1rem;
    --van-dialog-button-height:3rem;
    --van-button-default-font-size:1rem;
    --van-toast-font-size:1.25rem; */
}
</style>
